import { AxiosRequestConfig } from "axios";
import http from "../../api/axios";

class SchoolProgramsService {
  getAllTimeTables = (query: any, params?: AxiosRequestConfig): Promise<any> =>
    http.get("/timetables", { params: query });

  getAllTimeTablesLight = (params?: any): Promise<any> =>
    http.get("/timetables", {
      params: {
        light: 1,
        ...params,
      },
    });

  geTimeTable = (id: number): Promise<any> => http.get(`/timetables/${id}`);

  creatTimeTable = (data: any): Promise<any> => http.post("/timetables", data);

  updatTimeTable = (data: any, id: number): Promise<any> =>
    http.put(`/timetables/${id}`, data);

  deletTimeTable = (id: number): Promise<any> =>
    http.delete(`/timetables/${id}`);
}

export default SchoolProgramsService;
