import { AxiosRequestConfig } from "axios";
import http from "../../api/axios";
import { IRoleQuery } from "../../models/roles/query";
import { ICreateRole, IUpdateRole } from "../../models/roles/request";

class RolesService {
  getAllRoles = (
    query: IRoleQuery,
    params?: AxiosRequestConfig
  ): Promise<any> => http.get("/roles", { params: query });

  getAllRolesLight = (): Promise<any> =>
    http.get("/roles", {
      params: {
        light: 1,
      },
    });

  getRole = (id: number): Promise<any> => http.get(`/roles/${id}`);

  createRole = (data: ICreateRole): Promise<any> => http.post("/roles", data);

  updateRole = (data: IUpdateRole, id: number): Promise<any> =>
    http.put(`/roles/${id}`, data);

  deleteRole = (id: number): Promise<any> => http.delete(`/roles/${id}`);
}

export default RolesService;
