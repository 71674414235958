import { IInternalState, AuthLoading } from "./context";
import { toggleLoading } from "../../utils/helpers/functions";
import { IUserLogin } from "../../models/user-login/response";

type Action =
  | { type: "LOADING"; payload: { loading: AuthLoading | AuthLoading[] } }
  | { type: "IS_AUTHENTICATED"; payload: { isAuthenticated: boolean } }
  | { type: "LOGOUT" }
  | { type: "LOGIN"; payload: { user?: any } }
  | { type: "USER"; payload: { user?: any } };

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "IS_AUTHENTICATED":
      return { ...state, isAuthenticated: action.payload.isAuthenticated };
    case "LOADING":
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    case "LOGOUT":
      return {
        ...state,
        authUser: undefined,
        isAuthenticated: false,
        userPermissions: [],
      };
    case "LOGIN":
      return {
        ...state,
        authUser: action.payload?.user,
        isAuthenticated: true,
        userPermissions:
          action.payload.user?.roles[0]?.permissions.map(
            (item: any) => item.name
          ) ?? [],
      };

    case "USER": {
      return {
        ...state,
        authUser: action.payload?.user,
        userPermissions:
          action.payload.user?.roles[0]?.permissions.map(
            (item: any) => item.name
          ) ?? [],
      };
    }
    default:
      return state;
  }
};

export default reducer;
