import { AxiosRequestConfig } from "axios";
import http from "../../api/axios";
import { ILessonQuery } from "../../models/lessons/query";
import { ICreateLesson, IUpdateLesson } from "../../models/lessons/request";

class AttendancesService {
  getAllAttendances = (query: any, params?: AxiosRequestConfig): Promise<any> =>
    http.get("/attendances", { params: query });

  getAllAttendacesLight = (): Promise<any> =>
    http.get("/attendances", {
      params: {
        light: 1,
      },
    });

  getAttendance = (id: number): Promise<any> => http.get(`/attendances/${id}`);

  createAttendace = (data: any): Promise<any> =>
    http.post("/attendances", data);

  updateAttendance = (data: any, id: number): Promise<any> =>
    http.put(`/attendances/${id}`, data);

  deleteAttendace = (id: number): Promise<any> =>
    http.delete(`/attendances/${id}`);
}

export default AttendancesService;
