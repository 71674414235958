import { AxiosRequestConfig } from "axios";
import http from "../../api/axios";
import { IClassRoomQuery } from "../../models/classrooms/query";
import {
  ICreateClassRoom,
  IUpdateClassRoom,
} from "../../models/classrooms/request";

class ClassroomsService {
  getAllClassrooms = (
    query: IClassRoomQuery,
    params?: AxiosRequestConfig
  ): Promise<any> => http.get("/classrooms", { params: query });

  getAllClassroomsLight = (params?: any): Promise<any> =>
    http.get("/classrooms", {
      params: {
        light: 1,
        ...params,
      },
    });

  getClassRoom = (id: number): Promise<any> => http.get(`/classrooms/${id}`);

  createClassRoom = (data: ICreateClassRoom): Promise<any> =>
    http.post("/classrooms", data);

  updateClassRoom = (data: IUpdateClassRoom, id: number): Promise<any> =>
    http.put(`/classrooms/${id}`, data);

  deleteClassRoom = (id: number): Promise<any> =>
    http.delete(`/classrooms/${id}`);
}

export default ClassroomsService;
