import { AxiosRequestConfig } from "axios";
import http from "../../api/axios";
import { ISemesterQuery } from "../../models/semesters/query";
import {
  ICreateSemester,
  IUpdateSemester,
} from "../../models/semesters/request";

class SemesterService {
  getAllSemesters = (
    query: ISemesterQuery,
    params?: AxiosRequestConfig
  ): Promise<any> => http.get("/semester", { params: query });

  getAllSemestersLight = (params?: any): Promise<any> =>
    http.get("/semester", {
      params: {
        light: 1,
        ...params,
      },
    });

  getSemester = (id: number): Promise<any> => http.get(`/semester/${id}`);

  createSemester = (data: ICreateSemester): Promise<any> =>
    http.post("/semester", data);

  updateSemester = (data: IUpdateSemester, id: number): Promise<any> =>
    http.put(`/semester/${id}`, data);

  deleteSemester = (id: number): Promise<any> => http.delete(`/semester/${id}`);
}

export default SemesterService;
