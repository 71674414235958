import { AxiosRequestConfig } from "axios";
import http from "../../api/axios";
import { ITeacherQuery } from "../../models/teachers/query";
import { ICreateTeacher, IUpdateTeacher } from "../../models/teachers/request";

class TeachersService {
  getAllTeachers = (
    query: ITeacherQuery,
    params?: AxiosRequestConfig
  ): Promise<any> => http.get("/teachers", { params: query });

  getAllTeachersLight = (params?: any): Promise<any> =>
    http.get("/teachers", {
      params: {
        light: 1,
        ...params,
      },
    });

  getTeacher = (id: number): Promise<any> => http.get(`/teachers/${id}`);

  createTeacher = (data: ICreateTeacher): Promise<any> =>
    http.post("/teachers", data);

  updateTeacher = (data: IUpdateTeacher, id: number): Promise<any> =>
    http.put(`/teachers/${id}`, data);

  deleteTeacher = (id: number): Promise<any> => http.delete(`/teachers/${id}`);
}

export default TeachersService;
