import { AxiosRequestConfig } from "axios";
import http from "../../api/axios";
import { IEmployeeQuery } from "../../models/employees/query";
import {
  ICreateEmployee,
  IUpdateEmployee,
} from "../../models/employees/request";

class EmployeesService {
  getAllEmployees = (
    query: IEmployeeQuery,
    params?: AxiosRequestConfig
  ): Promise<any> => http.get("/employees", { params: query });

  getAllEmployeesLight = (): Promise<any> =>
    http.get("/employees", {
      params: {
        light: 1,
      },
    });

  getEmployee = (id: number): Promise<any> => http.get(`/employees/${id}`);

  createEmployee = (data: ICreateEmployee): Promise<any> =>
    http.post("/employees", data);

  updateEmployee = (data: IUpdateEmployee, id: number): Promise<any> =>
    http.put(`/employees/${id}`, data);

  deleteEmployee = (id: number): Promise<any> =>
    http.delete(`/employees/${id}`);
}

export default EmployeesService;
