import { AxiosRequestConfig } from "axios";
import http from "../../api/axios";

import { IGradeQuery } from "../../models/grades/query";
import { ICreateGrade, IUpdateGrade } from "../../models/grades/request";

class CourseService {
  getAllCourses = (
    query: IGradeQuery,
    params?: AxiosRequestConfig
  ): Promise<any> => http.get("/courses", { params: query });

  getAllCoursesLight = (params?: any): Promise<any> =>
    http.get("/courses", {
      params: {
        light: 1,
        ...params,
      },
    });

  getCourse = (id: number): Promise<any> => http.get(`/courses/${id}`);

  createCourse = (data: ICreateGrade): Promise<any> =>
    http.post("/courses", data);

  updateCourse = (data: IUpdateGrade, id: number): Promise<any> =>
    http.put(`/courses/${id}`, data);

  deleteCourse = (id: number): Promise<any> => http.delete(`/courses/${id}`);
}

export default CourseService;
