import { AxiosRequestConfig } from "axios";
import http from "../../api/axios";
import { IRoleQuery } from "../../models/roles/query";
import { ICreateRole, IUpdateRole } from "../../models/roles/request";

class PermissionsService {
  getAllPermissions = (
    query: IRoleQuery,
    params?: AxiosRequestConfig
  ): Promise<any> => http.get("/permissions", { params: query });
}

export default PermissionsService;
