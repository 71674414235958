import { AxiosRequestConfig } from "axios";
import http from "../../api/axios";
import { ISubjectQuery } from "../../models/subjects/query";
import { ICreateSubject, IUpdateSubject } from "../../models/subjects/request";

class SubjectsService {
  getAllSubjects = (
    query: ISubjectQuery,
    params?: AxiosRequestConfig
  ): Promise<any> => http.get("/subjects", { params: query });

  getAllSubjectsLight = (params?: any): Promise<any> =>
    http.get("/subjects", {
      params: {
        light: 1,
        ...params,
      },
    });

  getSubject = (id: number): Promise<any> => http.get(`/subjects/${id}`);

  createSubject = (data: ICreateSubject): Promise<any> =>
    http.post("/subjects", data);

  updateSubject = (data: IUpdateSubject, id: number): Promise<any> =>
    http.put(`/subjects/${id}`, data);

  deleteSubject = (id: number): Promise<any> => http.delete(`/subjects/${id}`);
}

export default SubjectsService;
