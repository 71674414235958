import { AxiosRequestConfig } from "axios";
import http from "../../api/axios";
import { IParentsQuery } from "../../models/parents/query";
import { ICreateParent, IUpdateParent } from "../../models/parents/request";

class ParentsService {
  getAllParents = (
    query: IParentsQuery,
    params?: AxiosRequestConfig
  ): Promise<any> => http.get("/parents", { params: query });

  getAllParentsLight = (params?: any): Promise<any> =>
    http.get("/parents", {
      params: {
        light: 1,
        ...params,
      },
    });

  geParent = (id: number): Promise<any> => http.get(`/parents/${id}`);

  creatParent = (data: ICreateParent): Promise<any> =>
    http.post("/parents", data);

  updatParent = (data: IUpdateParent, id: number): Promise<any> =>
    http.put(`/parents/${id}`, data);

  deletParent = (id: number): Promise<any> => http.delete(`/parents/${id}`);
}

export default ParentsService;
