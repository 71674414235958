import { AxiosRequestConfig } from "axios";
import http from "../../api/axios";

import { IGradeQuery } from "../../models/grades/query";
import { ICreateGrade, IUpdateGrade } from "../../models/grades/request";

class GradesService {
  getAllGrades = (
    query: IGradeQuery,
    params?: AxiosRequestConfig
  ): Promise<any> => http.get("/grades", { params: query });

  getAllGradesLight = (params?: any): Promise<any> =>
    http.get("/grades", {
      params: {
        light: 1,
        ...params,
      },
    });

  getGrade = (id: number): Promise<any> => http.get(`/grades/${id}`);

  createGrade = (data: ICreateGrade): Promise<any> =>
    http.post("/grades", data);

  updateGrade = (data: IUpdateGrade, id: number): Promise<any> =>
    http.put(`/grades/${id}`, data);

  deleteGrade = (id: number): Promise<any> => http.delete(`/grades/${id}`);
}

export default GradesService;
