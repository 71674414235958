import { AxiosRequestConfig } from "axios";
import http from "../../api/axios";
import { IStudentQuery } from "../../models/students/query";
import { ICreateStudent, IUpdateStudent } from "../../models/students/request";

class StudentsService {
  getAllStudents = (query: IStudentQuery, params?: any): Promise<any> =>
    http.get("/students", { params: query });

  getAllStudentsLight = (params: any): Promise<any> =>
    http.get("/students", {
      params: {
        light: 1,
        ...params,
      },
    });

  getStudent = (id: number): Promise<any> => http.get(`/students/${id}`);

  createStudent = (data: ICreateStudent): Promise<any> =>
    http.post("/students", data);

  updateStudent = (data: IUpdateStudent, id: number): Promise<any> =>
    http.put(`/students/${id}`, data);

  deleteStudent = (id: number): Promise<any> => http.delete(`/students/${id}`);
}

export default StudentsService;
