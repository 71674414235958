import http from "../../api/axios";
import { ILogin } from "../../models/user-login/request";
import { IUserLogin } from "../../models/user-login/response";

class AuthService {
  constructor() {}
  login = (data: ILogin, url: string): Promise<any> => http.post(url, data);
  logout = (data: any): Promise<any> => http.post("/auth/logout");

  // logout = (params?: RequestParams) =>
  //   this.http.request<void>({
  //     path: `/auth/sign-out`,
  //     method: "POST",
  //     ...params,
  //   })

  me = (url: string) => http.get(url);

  changePassword = (data: any): Promise<any> =>
    http.post("/auth/change-password", data);

  updateProfile = (data: any): Promise<any> => http.put("/auth/me", data);
}

export default AuthService;
